<template>
  <v-card>
    <v-card-title>
      <template v-if="network.icon">
        <v-icon class="pb-1 mr-2" large :color="network.color">{{ network.icon }}</v-icon>
      </template>
      <template v-else-if="network.image">
        <v-img class="pb-1 mr-2 d-inline-flex" :src="network.image" contain max-width="36px"></v-img>
      </template><span class="title">{{ network.text }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="hideCard = !hideCard">
        <v-icon>{{ hideCard ? icons.mdiWindowMaximize : icons.mdiWindowMinimize }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <template v-if="!canTarget">
      <v-card-text>
        <v-expand-transition>
          <v-card class="mt-2" v-show="!hideCard" outlined>
            <v-card-title>Select the type of campaign you want to create</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container fluid>
                <v-row justify="space-around" align="center">
                  <!-- <v-col class="text-center" v-if="hasCampaignType('advantage')" cols="12" sm="8" md="4" lg="3">
                    <v-btn outlined large @click="setCampaignType('advantage')">ADVANTAGE</v-btn>
                  </v-col>
                  <v-col class="text-center" v-if="hasMaiDefaultCampaign()" cols="12" sm="8" md="4" lg="3">
                    <v-btn outlined large @click="setCampaignType('default')">MAI (Default)</v-btn>
                  </v-col>
                  <v-col class="text-center" v-if="hasCampaignType('controlTest') && !isIos" cols="12" sm="8" md="4" lg="3">
                    <v-btn outlined large @click="setCampaignType('controlTest')">Split Test</v-btn>
                  </v-col>
                  <v-col class="text-center" v-if="hasCampaignType('cpi')" cols="12" sm="8" md="4" lg="3">
                    <v-btn outlined large @click="setCampaignType('cpi')">CPI</v-btn>
                  </v-col>
                  <v-col class="text-center" v-if="hasCampaignType('roas_ad_based')" cols="12" sm="8" md="4" lg="3">
                    <v-btn outlined large @click="setCampaignType('roas_ad_based')">ROAS (Ad based)</v-btn>
                  </v-col>
                  <v-col class="text-center" v-if="hasCampaignType('roas_iap')" cols="12" sm="8" md="4" lg="3">
                    <v-btn outlined large @click="setCampaignType('roas_iap')">ROAS (IAP)</v-btn>
                  </v-col>
                  <v-col class="text-center" v-if="hasMaiDefaultCampaign()" cols="12" sm="8" md="4" lg="3">
                    <v-btn outlined large @click="setCampaignType('default')">MAI (Default)</v-btn>
                  </v-col> -->
                  <v-col class="text-center" v-if="hasManualCampaign()" cols="12" sm="8" md="4" lg="3">
                    <v-btn outlined large @click="setCampaignType('manual')">Manual App Ads</v-btn>
                  </v-col>
                  <!-- <v-col class="text-center" v-if="hasCampaignType('splitTest') && !isIos" cols="12" sm="8" md="4" lg="3">
                    <v-btn outlined large @click="setCampaignType('splitTest')">Split Test</v-btn>
                  </v-col> -->
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-card-text>
    </template>
    <template v-else>
      <template v-if="isMultiCountries">
        <template v-for="(message, name) in warnings">
          <v-alert class="mb-0" v-if="message" type="warning" outlined text v-bind:key="name">
            <h4>{{ message }}</h4>
          </v-alert>
        </template>
        <v-card-text>
          <v-expand-transition>
            <v-container v-show="!hideCard" fluid>
              <template v-if="mightNeedCreatives || mightNeedCampaignTypeSelection">
                <v-row justify="center" align="center">
                  <v-col class="text-center" cols="6">
                    <v-btn outlined @click="canTarget = false">Back to campaign type choice</v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-if="canTargetPlatforms">
                <v-container fluid>
                  <v-row class="mt-5" justify="start">
                    <v-col cols="12" sm="2" md="2" lg="2">
                      <h3 class="mt-2">Placement mode</h3>
                    </v-col>
                    <v-radio-group v-model="targetingMode" row @change="onTargetingModeChange">
                      <v-radio class="mr-4" key="auto" label="Automatic" value="auto"></v-radio>
                      <v-radio class="mr-4" key="manual" label="Manual" value="manual"></v-radio>
                    </v-radio-group>
                  </v-row>
                </v-container>
                <v-container fluid>
                  <v-row justify="start">
                    <v-col cols="12" sm="2" md="2" lg="2">
                      <h3 class="mt-2">Platforms to target</h3>
                    </v-col>
                    <v-checkbox class="mr-4" v-for="target in network.platformTargets" multiple hide-details v-model="targetedPlatforms" :label="target.label" :value="target.value" :key="target.value" :rules="[targetedPlatformRule]" :disabled='targetingMode === "auto"'></v-checkbox>
                  </v-row>
                </v-container>
                <v-divider class="mt-10"></v-divider>
              </template>
              <div v-for="(campaign, index) in campaigns" :key="index">
                <campaign :index="index" :network="network" :campaign="campaign" :needsCreative="needsCreative" :campaignType="campaignType" ref="campaigns" @update="updateCampaignCountries" @isValid="updateValid"></campaign>
              </div>
            </v-container>
          </v-expand-transition>
        </v-card-text>
        <template v-for="(message, name) in warnings">
          <v-alert class="mb-0" v-if="message" type="warning" outlined text v-bind:key="name">
            <h4>{{ message }}</h4>
          </v-alert>
        </template>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn @click="addCampaign" color="primary" v-if="!isSplitTestOrControlTest">Add campaign</v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text>
          <v-expand-transition>
            <v-container v-show="!hideCard" fluid>
              <template v-if="mightNeedCreatives || mightNeedCampaignTypeSelection">
                <v-row justify="center" align="center">
                  <v-col class="text-center" cols="6">
                    <v-btn outlined @click="canTarget = false">Back to campaign type choice</v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-if="canTargetPlatforms">
                <v-container fluid>
                  <v-row class="mt-5" justify="start">
                    <v-col cols="12" sm="2" md="2" lg="2">
                      <h3 class="mt-2">Placement mode</h3>
                    </v-col>
                    <v-radio-group v-model="targetingMode" row @change="onTargetingModeChange">
                      <v-radio class="mr-4" key="auto" label="Automatic" value="auto"></v-radio>
                      <v-radio class="mr-4" key="manual" label="Manual" value="manual"></v-radio>
                    </v-radio-group>
                  </v-row>
                </v-container>
                <v-container fluid>
                  <v-row justify="start">
                    <v-col cols="12" sm="2" md="2" lg="2">
                      <h3 class="mt-2">Platforms to target</h3>
                    </v-col>
                    <v-checkbox class="mr-4" v-for="target in network.platformTargets" multiple hide-details v-model="targetedPlatforms" :label="target.label" :value="target.value" :key="target.value" :rules="[targetedPlatformRule]" :disabled='targetingMode === "auto"'></v-checkbox>
                  </v-row>
                </v-container>
                <v-divider class="mt-10 mb-10"></v-divider>
              </template>
              <v-row justify="center" align="center">
                <v-col cols="12">
                  <countries :with-tier="!needsCreative" :network="network" ref="countries" @update="updateSelectedCountries"></countries>
                </v-col>
                <!-- <template v-if="!needsCreative && filteredTypeNames">
                  <template v-for="item in filteredTypeNames" >
                    <v-col class="d-flex justify-center" :cols="numberOfToggle" v-bind:key="item">
                      <v-switch :label="formatCampaignTypeText(item)" @change="toggleCampaignType(item)" hide-details></v-switch>
                    </v-col>
                  </template>
                </template> -->
                <v-col class="d-flex justify-center" v-if="!needsCreative && !(network.hide && network.hide.options && network.hide.options.worldwide)" :cols="numberOfToggle">
                  <v-switch label="Exclude worldwide" v-model="worldwide" :disabled="network.default && network.default.excludeWorldwide !== undefined" hide-details></v-switch>
                </v-col>
                <template v-if="isIos && !(network.hide && network.hide.options && network.hide.options.ios14)">
                  <v-col class="d-flex justify-center" :cols="numberOfToggle">
                    <v-switch label="Exclude iOS 14 and above" v-model="ios14" hide-details></v-switch>
                  </v-col>
                </template>
                <v-col cols="12">
                  <budgets @isValid="updateValid" :multiCountry="false" :countries="countries.selected" :network="network" :campaignsType="specialCampaigns" :ios14Min="ios14Min" :needsCreative="needsCreative" :campaignType="campaignType" ref="budget"></budgets>
                </v-col>
              </v-row>
            </v-container>
          </v-expand-transition>
        </v-card-text>
      </template>
    </template>
  </v-card>
</template>

<script>
import { mdiWindowMinimize, mdiWindowMaximize } from '@mdi/js'
import { mapState } from 'vuex'

import Countries from './countries.vue'
import Budgets from './budgets.vue'
import Campaign from './campaign.vue'

export default {
  name: 'BudgetCard',

  components: {
    Budgets,
    Countries,
    Campaign
  },

  props: {
    network: {
      required: true,
      type: Object
    }
  },

  mounted () {
    this.canTarget = !this.mightNeedCreatives && !['applovin', 'facebook'].includes(this.network.value) // applovin does not need creatives but we still want to have campaign type selection for it
    if (this.isIos && ['tiktok', 'snapchat', 'facebook'].includes(this.network.value)) this.ios14Min = true
  },

  data: () => ({
    icons: { mdiWindowMinimize, mdiWindowMaximize },
    hideCard: false,
    ios14: false,
    ios14Min: false,
    specialCampaigns: [],
    worldwide: false,
    campaignType: null,
    canTarget: false,
    campaigns: [],
    countries: {
      selected: []
    },
    warnings: {},
    targetingMode: 'auto',
    targetedPlatforms: []
  }),

  beforeMount () {
    this.worldwide = !!this.network.default && !!this.network.default.excludeWorldwide
    this.targetedPlatforms = this.network.platformTargets ? this.network.platformTargets.map((t) => t.value) : []
  },

  computed: {
    ...mapState('acquisition', ['platform', 'budgetTemplate']),

    campaignsTypeName () {
      return this.network.campaignsType && Object.keys(this.network.campaignsType)
    },

    filteredTypeNames () {
      // controlTest is just for Tiktok
      const blacklist = ['controlTest', 'roas_ad_based', 'roas_iap', 'cpi']
      return this.campaignsTypeName && this.campaignsTypeName.filter((type) => !blacklist.includes(type))
    },

    isIos () { return this.platform === 'ios' },

    isMultiCountries () {
      return this.network.multiCountries && (this.isIos || this.campaignType === 'advantage')
    },

    mightNeedCreatives () {
      return this.hasCampaignType('advantage') ||
        // Split Test campaigns should only be available for android
        (this.hasCampaignType('splitTest') && !this.isIos) ||
        // Control Test campaigns should only be available for android
        (this.hasCampaignType('controlTest') && !this.isIos)
    },

    mightNeedCampaignTypeSelection () {
      return this.hasCampaignType('roas_ad_based') || this.hasCampaignType('roas_iap') || this.hasCampaignType('advantage')
    },

    isSplitTestOrControlTest () {
      return [
        'splitTest',
        'controlTest'
      ].includes(this.campaignType)
    },

    needsCreative () {
      return [
        'splitTest',
        'controlTest',
        'advantage'
      ].includes(this.campaignType)
    },

    numberOfToggle () {
      const nbToggle = this.isIos ? 2 : 1
      const nbOptions = this.network.campaignsType ? Object.keys(this.network.campaignsType).length : 0
      return 12 / (nbToggle + nbOptions)
    },

    options () {
      const options = {
        exclude: {
          worldwide: this.worldwide,
          ios14: this.ios14
        },
        targetedPlatforms: this.targetingMode === 'manual' ? this.targetedPlatforms : undefined
      }
      return options
    },

    canTargetPlatforms () {
      return this.network.platformTargets && this.network.platformTargets.length
    },
    targetedPlatformRule () {
      return (!!this.targetedPlatforms && !!this.targetedPlatforms.length) || 'At least one platform should be selected'
    }
  },

  methods: {
    updateValid ({ network, bool }) {
      this.$emit('isValid', { network, bool })
    },
    setCampaignType (type) {
      this.canTarget = true
      this.campaignType = type
    },
    hasCampaignType (type) {
      return this.campaignsTypeName && this.campaignsTypeName.includes(type)
    },
    hasMaiDefaultCampaign () {
      return !['applovin', 'facebook'].includes(this.network.value)
    },
    hasManualCampaign () {
      return ['facebook'].includes(this.network.value)
    },
    toggleCampaignType (value) {
      const type = value.toUpperCase()
      const indexCampaign = this.specialCampaigns.map(campaign => campaign.type).indexOf(type)
      if (indexCampaign < 0) {
        this.specialCampaigns.push({ country: 'US', type })
      } else {
        this.specialCampaigns.splice(indexCampaign, 1)
      }
    },
    addCampaign () {
      this.campaigns.push({})
    },
    formatCampaignTypeText (type) {
      const typeString = type === 'inf' ? 'influencer' : type
      const capitalized = typeString.charAt(0).toUpperCase() + typeString.slice(1)
      return `Create campaign ${capitalized}`
    },
    handleBaseCountryCampaigns (countries) {
      const campaignsBaseCountries = this.campaigns.filter(c => c.baseCountry).map(c => c.baseCountry)

      const addedCountries = countries.filter((country) => !campaignsBaseCountries.includes(country))
      const removedCountries = campaignsBaseCountries.filter((country) => !countries.includes(country))

      addedCountries.forEach(country => this.campaigns.push({ baseCountry: country }))

      removedCountries.forEach(country => {
        const index = this.campaignIndex(country)

        if (index >= 0) {
          this.$set(this.campaigns, index, { baseCountry: '', countries: [], deleted: true })
        }
      })
    },
    updateBaseCountries (countries) {
      (this.isMultiCountries || this.isFacebook())
        ? this.handleBaseCountryCampaigns(countries)
        : this.$refs.countries && this.$refs.countries.setBase(countries)
    },
    isFacebook () {
      return this.network.value === 'facebook'
    },
    updateSelectedCountries (countries) {
      if (this.needsCreative) {
        if (countries.length && this.countries.selected.length) return
      }

      this.countries.selected = countries
    },
    updateCampaignCountries ({ countries, campaignIndex }) {
      this.$set(this.campaigns[campaignIndex], 'countries', countries)
      this.countries.selected = this.campaigns.map(c => c.countries || []).flat()
    },
    onTargetingModeChange (mode) {
      if (mode === 'auto') this.targetedPlatforms = this.network.platformTargets.map((t) => t.value)
    },
    campaignIndex (country) {
      return this.campaigns.findIndex(c => {
        return c.baseCountry === country && (!c.countries || (c.countries[0] === country && c.countries.length === 1))
      })
    },
    budgets () {
      return this.isMultiCountries
        ? this.$refs.campaigns.map(c => c.budgets())
        : this.$refs.budget.budgets.map(b => [b])
    },
    checkCampaignLimit () {
      if (this.campaigns.filter(c => !c.deleted).length > this.network.campaignLimit) {
        this.$set(
          this.warnings,
          'campaignLimit',
          `${this.network.text} has a campaign limit of ${this.network.campaignLimit}`
        )
      } else {
        this.$set(this.warnings, 'campaignLimit', null)
      }
    },
    checkCountryDuplicate () {
      if (new Set(this.countries.selected).size !== this.countries.selected.length) {
        this.$set(
          this.warnings,
          'countryDuplicate',
          'Some countries are present in multiple campaigns or adgroups'
        )
      } else {
        this.$set(this.warnings, 'countryDuplicate', null)
      }
    }
  },

  watch: {
    countries: {
      deep: true,
      handler () {
        this.checkCountryDuplicate()
      }
    },

    campaigns: {
      deep: true,
      handler () {
        this.campaigns.filter(c => !c.deleted).forEach((c, index) => { c.name = index + 1 })
        if (this.$refs.campaigns) {
          this.$refs.campaigns.map(c => c.$forceUpdate())
        }

        this.checkCampaignLimit()
      }
    },

    budgetTemplate: {
      deep: true,
      handler (template) {
        const currentLines = template.filter(({ network }) => network === this.network.value)

        if (!currentLines.length) return

        const countries = currentLines.reduce((acc, { targeting }) => [...acc, targeting], [])

        this.updateBaseCountries(countries)
      }
    }
  }
}
</script>
