<template lang="pug">
  v-stepper-content(step='4')
    v-card
      v-card-title
        div Setup Bids and budget
        v-spacer
        import-dialog
        v-btn(
          @click='create',
          :disabled='!canCreate',
          color='primary'
        ) Create

      v-divider
      v-card-text
        countries.px-4(is-main, with-tier, @update='updateBaseCountries')

        v-container(fluid)
          template(
            v-for='network in networks.selected'
          )
            template(v-if="['facebook', 'snapchat'].includes(network.value)")
              budget-card(
                :key='network.value'
                :network='network',
                ref='budgets',
                @isValid='updateValid'
              )

      v-divider

      v-card-actions.px-4
        v-spacer
        v-btn(@click='step -= 1', color='secondary') Previous

        v-btn(@click='create', :disabled='!canCreate', color='primary') Create
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { mdiExpandAll } from '@mdi/js'

import Countries from './countries.vue'
import BudgetCard from './budgetCard.vue'
import importDialog from './importDialog.vue'

import step from '@/mixins/acquisition/step.js'

export default {
  name: 'Setup',

  mixins: [step],

  components: {
    BudgetCard,
    Countries,
    importDialog
  },

  data: () => ({
    icons: { mdiExpandAll },

    tab: 1,
    valid: {}
  }),

  computed: {
    ...mapState('acquisition', [
      'networks'
    ]),

    isAllExpanded () {
      return this.panels.length === this.networks.selected.length
    },
    canCreate () {
      const values = Object.values(this.valid)

      return values.length
        ? values.every(Boolean)
        : false
    }
  },

  methods: {
    ...mapMutations('acquisition', ['setBudgets']),

    updateBaseCountries (countries) {
      this.$refs.budgets.forEach((budget) => {
        budget.updateBaseCountries(countries)
      })
    },
    updateValid ({ bool, network }) {
      this.$set(this.valid, network.value, bool)
    },

    getBudgets () {
      return this.$refs.budgets.reduce((acc, { budgets, options, network, campaignType }) => ({
        ...acc,
        [network.value]: {
          budgets: budgets().filter(b => b.length),
          options,
          network,
          type: campaignType
        }
      }), {})
    },

    async create () {
      this.setBudgets(this.getBudgets())

      this.$emit('create')
    }
  },

  watch: {
    step () {
      if (this.step === 4) {
        // Resetting panels so that it is all opened
        this.panels = []
      }
    }
  }
}
</script>
