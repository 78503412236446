import { mdiFacebook, mdiGoogleAdwords, mdiSnapchat } from '@mdi/js'

export default {
  step: 1,
  loading: false,
  platform: 'ios',
  creatives: {
    error: null,
    available: null
  },
  budgets: [],
  budgetTemplate: [],

  networks: {
    selected: [],
    all: [
      { header: 'Direct Creation' },
      {
        value: 'adwords',
        text: 'Adwords',
        disabled: false,
        email: false,
        icon: mdiGoogleAdwords,
        color: 'default',
        multiCountries: true,
        campaignsType: { inf: false },
        campaignLimit: 8
      },
      // {
      //   value: 'apple',
      //   text: 'Apple',
      //   disabled: false,
      //   email: false,
      //   icon: mdiApple,
      //   color: 'default',
      //   multiCountries: false,
      //   hide: { options: { ios14: true, worldwide: true } }
      // },
      // {
      //   value: 'applovin',
      //   text: 'Applovin',
      //   disabled: false,
      //   /**
      //    * email true will setup old way to create campaigns
      //    */
      //   email: false,
      //   image: LogoApplovin,

      //   /**
      //    * When email is false, user will setup the campaign through kitchen
      //    * When multicountry is true:
      //    *  - is just for iOS
      //    *  - there are 2 choices in the screen
      //    *    - group countries to have same bid + budget (business workaround due to network limitations to create max adgroups)
      //    *    - by default, 1 campaign per country
      //    */
      //   multiCountries: false,
      //   campaignsType: { cpi: false, roas_ad_based: false /*, roas_iap: false */ }
      // },
      {
        value: 'facebook',
        text: 'Facebook',
        disabled: false,
        email: false,
        icon: mdiFacebook,
        color: 'blue',
        multiCountries: true,
        multiAdgroups: true,
        campaignLimit: 9,
        adgroupLimit: 5,
        ios14MinOnlyCampaign: true,
        hide: { bid: true },
        campaignsType: { rush: false, playable: false, inf: false, splitTest: false, advantage: false },
        default: { excludeWorldwide: true },
        platformTargets: [
          { label: 'Facebook', value: 'facebook' },
          { label: 'Instagram', value: 'instagram' },
          { label: 'Messenger', value: 'messenger' },
          { label: 'Audience Network', value: 'audience_network' }
        ],
        optimizationType: [
          { value: 'app_installs', label: 'App installs' },
          { value: 'value', label: 'Value' }
          // { value: 'app_events', label: 'App events', disabled: true }
        ],
        optimizationAvailableFor: [
          'advantage'
        ],
        bidStrategy: {
          value: [
            { value: 'highest_value', label: 'Highest value' },
            { value: 'roas_goal', label: 'ROAS goal' }
          ]
        }
      },
      // {
      //   value: 'mintegral',
      //   text: 'Mintegral',
      //   disabled: false,
      //   email: false,
      //   image: LogoMintegral
      // },
      {
        value: 'snapchat',
        text: 'Snapchat',
        disabled: false,
        email: false,
        icon: mdiSnapchat,
        color: 'yellow',
        multiCountries: true,
        multiAdgroups: true,
        adgroupLimit: 10,
        ios14MinOnlyCampaign: true
      }
      // {
      //   value: 'tiktok',
      //   text: 'Tiktok',
      //   disabled: false,
      //   email: false,
      //   image: LogoTiktok,
      //   multiCountries: true,
      //   campaignLimit: 11,
      //   ios14MinOnlyCampaign: true,
      //   campaignsType: {
      //     // split test disable because this feature is not being requested yet (went back to backlog)
      //     // splitTest: false,
      //     controlTest: false
      //   }
      // },
      // { value: 'voodooads', text: 'Voodoo Ads', disabled: true, email: false, image: LogoVoodoo },

      // { divider: true },
      // { header: 'Special' },
      // { value: 'crosspromo', text: 'Crosspromo', disabled: false, email: false, image: LogoVoodoo },

      // { divider: true },
      // { header: 'Emails' },
      // { value: 'crossinstall', text: 'Crossinstall', disabled: false, email: true, image: LogoCrossinstall },
      // { value: 'ironsource', text: 'Ironsource', disabled: false, email: true, image: LogoIronsource },
      // { value: 'tapjoy', text: 'Tapjoy', disabled: false, email: true, image: LogoTapjoy },
      // { value: 'unityads', text: 'Unity Ads', disabled: false, email: true, image: LogoUnity },
      // { value: 'vungle', text: 'Vungle', disabled: false, email: true, image: LogoVungle }
    ],
    configuration: {
      // IOS Distributions
      ios: {},
      ios_china: {},
      ios_line: {},

      // Android Distributions
      android: {},
      android_xiaomi: {},
      android_huawei: {},
      android_tencent: {},
      android_oppo: {},
      android_line: {}
    }
  },

  campaigns: {
    current: {
      error: null,
      batches: {
        all: []
      }
    },
    created: {
      data: [],
      error: null,
      done: false
    }
  }
}
