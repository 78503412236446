<template lang="pug">
  v-card-text
    v-container(fluid)
      template(v-if='!canInput')
        v-row(justify='space-around')
          //- v-btn(
          //-   color='primary',
          //-   :loading='creating',
          //-   @click='createAccount'
          //- ) Create New Account
          v-btn(
            color='secondary',
            :disabled='creating',
            @click='canInput = true'
          ) Link Existing Account

      template(v-else)
        v-form(v-model='valid')
          v-row
            v-col(cols='12')
              v-text-field(
                label='Account ID',
                v-model='accountId',
                :rules='[rules.notEmpty, rules.accountId]',
                :disabled='loading',
                outlined,
                clearable,
                persistent-hint,
                hint='Account ID in the Google Ads system'
              )

            v-col(cols='12')
              v-text-field(
                label='Link ID',
                v-model='linkId',
                :rules='[rules.notEmpty]',
                :disabled='loading',
                outlined,
                clearable,
                persistent-hint,
                hint='Link ID for that account'
              )

            v-col.d-flex.justify-end(cols='12')
              v-switch(
                v-model='isSetup',
                :disabled='loading',
                label='Toggle on if payment and conversions are setup',
                hide-details
              )

            v-col.text-right(cols='12')
              v-btn(
                :href='links.account',
                :disabled='loading',
                target='_blank',
                color='info',
                outlined
              )
                .pr-2 Manage Links
                v-icon {{ icons.mdiOpenInNew }}
              v-btn.mx-2(
                :href='links.payment',
                :disabled='loading',
                target='_blank',
                color='info',
                outlined
              )
                .pr-2 Manage Payment
                v-icon {{ icons.mdiOpenInNew }}
              v-btn(
                :href='links.conversions',
                :disabled='loading',
                target='_blank',
                color='info',
                outlined
              )
                .pr-2 Manage Conversions
                v-icon {{ icons.mdiOpenInNew }}

            v-col.d-flex.justify-end(cols='12')
              v-btn(
                @click='save',
                :loading='loading',
                :disabled='!valid',
                color='primary'
              )
                .pr-2 Save
                v-icon {{ icons.mdiContentSave }}
</template>

<script>
import { mdiOpenInNew, mdiContentSave } from '@mdi/js'

import Configuration from '@/mixins/acquisition/configuration.js'

export default {
  name: 'Adwords',

  mixins: [Configuration],

  props: {
    network: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    icons: {
      mdiOpenInNew,
      mdiContentSave
    },

    creating: false,
    canInput: false,
    valid: false,

    rules: {
      accountId: (value) => (!!value && `${value}`.replace(/-/g, '').length === 10) || 'Invalid Account ID',
      notEmpty: (value) => !!value || 'No value entered'
    },

    accountId: null,
    linkId: null,
    isSetup: false,

    links: {
      account: 'https://ads.google.com/aw/productlinks/thirdpartyappanalytics',
      payment: 'https://ads.google.com/aw/billing/signup',
      conversions: 'https://ads.google.com/aw/conversions'
    }
  }),

  computed: {
    configuration () {
      return {
        accountId: this.accountId,
        linkId: this.linkId,
        isSetup: this.isSetup
      }
    },

    canNext () {
      return this.valid && this.isSetup
    }
  },

  methods: {
    async createAccount () {
      this.creating = true

      try {
        const { data } = await this.$axios.post('/acquisition/action', {
          action: 'adwords-account-create',
          appId: this.currentAppId
        })

        this.accountId = data.accountId
        this.canInput = true
      } catch (e) {
        this.notify('Account creation failed, please try again or call an administrator', true)
      }

      this.creating = false
    }
  },

  watch: {
    canNext () {
      this.$emit('valid', { value: this.canNext, network: this.network.value })
    }
  }
}
</script>
