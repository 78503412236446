<template lang="pug">
  v-card-text
    v-container(fluid)
      template(v-if='!canInput')
        v-row(justify='space-around')
          //- v-btn(
          //-   color='primary',
          //-   @click='createAccount',
          //-   :loading='loading'
          //- ) Create New Account
          v-btn(
            color='secondary',
            @click='canInput = true'
          ) Enter Existing Account

      template(v-else)
        v-form(v-model='valid')
          v-row
            //- Account ID input
            v-col(cols='12')
              v-text-field(
                label='Account ID',
                v-model='accountId',
                :rules='[rules.accountId]',

                outlined,
                clearable,
                persistent-hint,
                hint='ID of the Snapchat ad account that should be used to launch ad campaigns'
              )

            v-divider

            v-col(cols='12')

              p Do not forget to #[a(:href='snapHelpLink', target='_blank') generate a Snap App ID] and #[a(:href='adjustHelpLink', target='_blank') add it to Adjust].
              v-text-field(
                label='Snap App Id',
                v-model='snapAppId',
                :rules='[rules.snapAppId]',

                outlined,
                clearable,
                persistent-hint,
                hint='ID of the Snapchat App'
              )

            //- Save button
            v-col.d-flex.justify-end(cols='12')
              v-btn(
                @click='save',
                :loading='loading',
                :disabled='!valid',
                color='primary'
              )
                .pr-2 Save
                v-icon {{ icons.mdiContentSave }}
</template>

<script>
import { mdiContentSave } from '@mdi/js'
import { validate } from 'uuid'

import Configuration from '@/mixins/acquisition/configuration.js'

export default {
  name: 'Snapchat',

  mixins: [Configuration],

  props: {
    network: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    icons: { mdiContentSave },

    rules: {
      accountId: (value) => (!!value && validate(value)) || 'Invalid Account ID',
      snapAppId: (value) => (!value || validate(value)) || 'Invalid SnapAppId ID'
    },

    canInput: false,
    valid: false,

    accountId: null,

    snapAppId: null,

    snapHelpLink: 'https://business.snapchat.com/7b673d9a-32f8-476f-80f7-5746f9551166/settings/mobile-apps?ref_aid=b86c5b93-c76f-4aea-b23d-53097f311ee5',

    adjustHelpLink: 'https://help.adjust.com/en/article/snapchat'
  }),

  computed: {
    configuration () {
      return {
        accountId: this.accountId,
        snapAppId: this.snapAppId
      }
    }
  },

  methods: {
    async createAccount () {
      this.loading = true

      try {
        const { data } = await this.$axios.post('/acquisition/action', {
          action: 'snapchat-account-create',
          appId: this.currentAppId
        })

        this.accountId = data.accountId
        this.canInput = true
      } catch (e) {
        this.notify('Account creation failed, please try again or call an administrator', true)
      }

      this.loading = false
    }
  },

  watch: {
    valid () {
      this.$emit('valid', { value: this.valid, network: this.network.value })
    }
  }
}
</script>
